import styled from "styled-components";
import { breakpoints } from "../../breakpoints";

export const NewsHolder = styled.div`
    padding: 1rem 0 3rem 0;
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
`;

export const NewsTitle = styled.h3`
    color: ${ ({ theme }) => theme.colors.primary };    
    font-size: 2rem;
`;
export const NewsItem = styled.div`
    display: flex;
    flex-direction: ${ ({ position }) => position === 'rigth' ? 'row-reverse' : 'row' };
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;    
    background-size: 100% 100%;
    transform-origin: 100% 100%;
    transform: skewY(-2deg);
    margin: 2rem 0;
    position: relative;    
    width: 60%; 
    min-height: 10rem;
    transition: all 200ms;
    &:hover{
        transform: skewY(-2deg) scale(1.1);
        box-shadow: 0 0 0.5rem 0 #000;
    }     
`;

export const NewsImage = styled.div`
    background-image: url(${ ({ url }) => url ? url : '/images/futura.jpg' });
    background-size: 100% 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;    
`;
export const NewsSpace = styled.div`
    flex: 1;
`;

export const NewsTextHolder = styled.div`
    flex: 1;
    padding: 0;    
    background-color: rgba(34,116,76,0.90); 
    box-shadow: inset 0 0 5rem rgba(0, 0, 0, 0.15);  
    height: 15rem;      
    width: 50%;   
    position: relative; 
`;

export const NewsText = styled.p`
    flex: 1;
    text-align: center;
    color: white;
    font-size: 2.3rem;
    margin: auto;
    width: 80%;
    height: 80%;
    text-overflow: ellipsis;         
    overflow: hidden;    
    margin-top:2rem;
    transform: skewY(2deg);
    @media (min-width: 0px) {        
        font-size: 1rem;
    }    
    @media ${breakpoints.tablet} {        
        font-size: 1.5rem;
    }
    @media ${breakpoints.laptopL} {        
        font-size: 2.3rem;
    }
`;

export const Image = styled.img`
    width: 5rem;
    right: 1.5rem;
    bottom: 1.5rem;
    position: absolute;    
    transition: all 500ms;  
    &:hover{
        transform: scale(1.1);
    } 
`;