import styled from "styled-components";
import { breakpoints } from "../../breakpoints";

export const SectionHolder = styled.div`
    padding: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    @media (min-width: 0px) {
        flex-direction: column;
    }    
    @media ${ breakpoints.tablet} {
        flex-direction: row;
    } 
`;


export const SectionItem = styled.div`
    padding: .5rem;
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    width: 40%;
`;

export const SectionTitle = styled.p`
    font-size: 1.4rem;
    color: ${ ({ theme }) => theme.colors.primary };
    margin: 0;
    font-family: Eras Light;
    font-style: italic;
`;

export const SectionSubtitle = styled.p`
    font-size: 1.1rem;
    color: #4f4f4f;
    font-family: Eras Light;     
    padding:0 5rem;
    font-style: italic;
    font-weight: lighter;
    text-align: center;
`;

export const SectionImage = styled.img`    
    width: 20rem;
    margin-bottom: 1rem;        
`;