import styled from "styled-components";
import { breakpoints } from "../../breakpoints";

export const ContactHolder = styled.div`
    background-image: url('/images/backgrounds/img_contenedor_correo.jpg');
    display: flex;
    flex-direction: column;
    align-items: center;    
    background-size: 100% 100%;    
    @media (min-width: 0px) {
        padding: 1.5rem;                 
    }    
    @media ${breakpoints.laptop} {
        padding: 3.5rem 5rem;
    }    
`;

export const ContactTitle = styled.p`
    font-size: 2.4rem;
    color: ${ ({ theme }) => theme.colors.white };
    margin: 0;
    font-family: Eras ;
`;

export const ContactBody = styled.p`
    font-size: 1.1rem;
    color: ${ ({ theme }) => theme.colors.white };
    font-family: Eras;
    margin: 0;    
    padding:0 10rem;
    text-align: center;
    font-weight: lighter;
`;
