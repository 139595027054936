import React, {useEffect, useState} from "react"
import {
    Nav,
    Layout,    
    Slides,        
    StarLoader,    
} from '../app/components'
import { NAVIGATION_ITEMS } from "../app/constants/nav"
import { 
  InfoSection, 
  MisionVisionSection, 
  ContactSection, 
  NewsSection, 
  Footer 
} from "../app/sections"
import { useSpring, animated } from "react-spring"
import { navigate } from 'gatsby';
import Axios from 'axios'

export default (props) => {    
    const fade = useSpring({
      from:{
        opacity: props.location.state?props.location.state.animation===false?1:0:0
      },
      opacity :1,
      config:{
        duration: 1500
      }
    })
    let showstar = true
    if (props.location.state && props.location.state.animation===false) {
      showstar= false
    }
    let nav = NAVIGATION_ITEMS    
    nav.rigthItems = NAVIGATION_ITEMS.rigthItems.map(item =>{       
      if (item.text === "Tu cuenta" ) {
        item.onclick = () => navigate('/profile')
      } 
      return item
    })    
    const [slides, setSlides] = useState([])
    useEffect( () => {
      const fetchNews = async () => {
          const response = await Axios.get('https://22uid7n4nl.execute-api.us-east-1.amazonaws.com/production/albums/corp1/elements')
          if ( response.status === 200 ) {
              setSlides(response.data._embedded.items.map(item=>{
                return{
                  title: "",
                  body: "",
                  image: item.resourceUrl,   
                  color: "rgba(119, 63, 123, 0);",                                                    
                  onClick: () => {}                  
                }
              }))
          }     
      }
      fetchNews();
  },[])
    return( 
      <>
      {showstar&&<StarLoader /> }
      <animated.div style={fade}>
        <Layout location={props.location}>   
          
            <Nav {...nav} />
            <Slides slides={
              slides.length>0?slides:
              [
                {
                  title: "",
                  body: "",
                  image: "/images/slides/GEB-PW.jpg",   
                  color: "rgba(119, 63, 123, 0);",                                                    
                  onClick: () => {}                            
                },
                {
                  title: "Carrera 80 aniversario",
                  body: "Como parte de los festejos del octagésimo aniversario del Grupo Estrella Blanca estos dueron los resultados y los ganadores de esta gran carrera ¡Revive este gran evento!",
                  image: "/images/slides/slide_1.jpg",   
                  color: "rgba(119, 63, 123, 0);",                                                    
                  onClick: () => {}                            
                },
                {
                  title: "Autobuses de Lujo",
                  body: "Viaja con nosotros",
                  image: "/images/slides/slide_2.jpg",  
                  color: "rgba(119, 63, 123, 0);",                   
                  onClick: () => {}                         
                }
                /*,
                {
                  title: "",
                  body: "",
                  image: "/images/slides/geb_sintomas.jpg",   
                  color: "rgba(119, 63, 123, 0);",                                                    
                  onClick: () => {}                            
                },
                {
                  title: "",
                  body: "",
                  image: "/images/slides/geb_medidas-de-prevencion.jpg",   
                  color: "rgba(119, 63, 123, 0);",                                                    
                  onClick: () => {}                            
                },*/
              ]} 
              autoMove
            />    
            <NewsSection />
            <InfoSection />  
            <MisionVisionSection />
            <ContactSection />  
            <Footer />                
        </Layout>
        </animated.div> 
        </>                        
    )
}
