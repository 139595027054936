import React from 'react'
import { SectionHolder, SectionItem, SectionTitle, SectionSubtitle, SectionImage } from './style';

const MisionVisionSection = props => {
    return(
        <SectionHolder>
            <SectionItem>
                <SectionTitle> Nuestra <b>Misión</b></SectionTitle>
                <SectionSubtitle>Brindar experiencias positivas en todos nuestros trayectos y servicios</SectionSubtitle>
                <SectionImage src={"/images/img_mision_izquierda.png"}/>
            </SectionItem>

            <SectionItem>
                <SectionTitle> Nuestra <b>Visión</b></SectionTitle>
                <SectionSubtitle>Ser la empresa estrella en rentabilidad y eficiencia donde las personas hacen la diferencia</SectionSubtitle>
                <SectionImage src={"/images/img_vision_derecha.png"}/>
            </SectionItem>
        </SectionHolder>
    )
}

export default MisionVisionSection;