import React from 'react'
import { animated, useSpring } from 'react-spring'
import { 
    InfoItem, 
    InfoTitle,
    ImageInfo,
    InfoNumber,
    InfoSubtitle
} from './style';
import VisibilitySensor from "react-visibility-sensor";

const InfoItemHolder = props => {
    const [{transform, opacity}, set] = useSpring(
        ()=>({
            transform: 'translate3d(0,-100px,100px)', 
            opacity:0
        }),
        {        
            config: {
                duration: Math.random()*2500
            }
        }
    )

    const toggleVisibility = (change) => {         
        if(change){
            set({
                transform: 'translate3d(0, 0, 0)', 
                opacity:1
            })
        } else {
            /*set({
                transform: 'translate3d(0,-100px,100px)', 
                opacity:0
            })*/
        }           
    }

    return(        
        <VisibilitySensor onChange={toggleVisibility}>
            <animated.div style={{ 
                flex:1,
                transform: transform.interpolate(transform => `${transform}`),
                opacity: opacity.interpolate(opacity=> `${opacity}`)
            }}>
                    <InfoItem>
                        <ImageInfo src={props.image} />
                        <InfoTitle>{props.title}</InfoTitle>
                        <InfoNumber>{props.number}</InfoNumber>
                        <InfoSubtitle>{props.subtitle}</InfoSubtitle>
                        <p>+ info</p>
                    </InfoItem>
            </animated.div>
        </VisibilitySensor>
    )
}

export default InfoItemHolder