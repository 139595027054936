import React, { useEffect, useState } from "react"
import { 
    NewsHolder,
    NewsItem,
    NewsSpace,
    NewsTextHolder,
    NewsText,
    NewsImage,
    Image,
    NewsTitle
} from './style';
import Axios from 'axios'
import Loader from "react-loader-spinner"
import { navigate } from "gatsby";
const NEWS =[
    {
        title: "Comunicado",
        img: "/images/img_noticia_1.jpg",
        fullTitle: 'Comunicado',                        
        date: new Date().toLocaleString(),
        body: '<img src="/images/image001.png" style="width:100%;" /><img src="/images/image002.png" style="width:100%;" />',        
        showImg: false
    },{
        title: "Medidas de prevención",
        img: "/images/img_noticia_2.jpg",
        fullTitle: 'Medidas de prevención',                        
        date: new Date().toLocaleString(),
        body: '<iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/H-iFghx-YM8" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',  
        showImg: false      
    },{
        title: "Nuestra historia",
        img: "/images/img_noticia_3.jpg",
        fullTitle: '',                        
        date: new Date().toLocaleString(),
        body: `<div class="content layout-align-start-stretch layout-column flex-auto" layout="column" layout-align="start none" flex="auto"><h1 translate="PAGES.historia" class="ng-scope ng-binding">Historia</h1><p>Conoce nuestra historia.</p><p>
        El final de la década de los años treinta fue un periodo de desarrollo en México, pasado el movimiento revolucionario el país entro en un estado de consolidación por lo que se atendió a las infraestructuras, que habían sido dañadas y no habían crecido, la mayoría se encontraba al mismo nivel que a finales de la primera década del siglo pasado. Un sector importante dentro de este movimiento fue el transporte. El gobierno federal invirtió fuertes cantidades en la construcción de carreteras para mejorar la comunicación entre los poblados. Aparejado a esto fue la creación de empresas para transportar pasajeros, tal fue el caso de las ciudades de Pachuca y Querétaro cuando los gobernadores de Hidalgo y Querétaro, Lic. Javier Rojo Gómez y Noradino Rubio respectivamente, promueven la creación de empresas transportistas, una de la cuales tuvo por nombre 'Sociedad Cooperativa de Transportes Nacionales del Centro Estrella Blanca'
        </p><p>
        En esa época un grupo de personas del estado de Hidalgo decidieron sumarse a este proceso. Lograron obtener el permiso para transportar pasajeros en la ruta México-Pachuca-Querétaro. Ninguno de ellos tenía una gran fortuna sin embargo lograron reunir con gran esfuerzo un capital de treinta y cuatro mil pesos, con el cual y junto con un crédito que obtuvieron, compraron seis camiones Ford con carrocerías de veintiún y veinticinco asientos. La mañana del 15 de septiembre de 1940. Se selló el primero de millones de boletos de pasaje que esta empresa, transformada, ha proporcionado desde entonces.
        </p><p>
        Estrella Blanca se caracterizó desde sus inicios por la fusión con otras líneas de transporte. Esto permitió tener acceso fácil a créditos refaccionarios y extender la cobertura ofrecida, aumentando la fuerza de la empresa lo que atrajo a nuevos socios. En un largo proceso que inicia en 1949, fue creciendo primero con líneas de servicio regular y luego de servicio de primera, como 'Transportes Chihuahuenses' en 1978; empezando por el centro y occidente del país, en seguida por el norte y el golfo, posteriormente por el sur, hasta llegar en el año de 1994 con la última adquisición, las empresas del grupo 'Tres Estrellas de Oro', que cubría la ruta de la Ciudad de México a Tijuana, llegando con esto a la actual cobertura en veintiséis estados de la república. En 1989 se creó Turistar, con la cual se incursiono en el servicio de tipo ejecutivo. Para 1992 se lanzó la marca Futura para el servicio de primera, que es la que tiene el mayor tamaño dentro del grupo. El servicio de paquetería se inició desde mediados de la década de los años sesenta, aprovechando las cajuelas de los autobuses, desde entonces ha sido un área importante dentro de la empresa.
        </p><p>
        Siempre buscando satisfacer las necesidades de transporte de sus clientes, la empresa ha creado recientemente marcas que tienen una cobertura regional, con una fuerte presencia local, como Rápidos Cuauhtémoc en el corredor de la ciudad de Chihuahua a Creel en la Sierra Tarahumara, Autobuses Seris de Hermosillo a Guaymas, Argenta en el trayecto de Aguascalientes a Zacatecas.
        </p><p>
        Con la inquietud de renovar para mejorar el servicio, en 2011 se han creado sub marcas que proporcionan un servicio inclusive mejor al de primera, conocido como 'Select', que cuentan con autobuses equipados con la tecnología más reciente en materia de seguridad y confort. Grupo Estrella Blanca estará siempre en la vanguardia de la transportación de pasajeros, sabe que no hay nada ganado, que tiene que continuar día a día trabajando por proporcionar un servicio de excelencia a sus clientes, con el mismo entusiasmo y alegría del primer día, como esa asoleada mañana de un 15 de septiembre en Querétaro y en la ciudad de México, que en 2015 cumplió setenta y cinco años.
        </p><div class="margin-bottom-30"></div></div>`,  
        showImg: true      
    }
]
const NewsSection = () => {
    const [ loading, setLoading ] = useState(true)
    const [ newsApi, setNews ] = useState([])

    useEffect( () => {
        const fetchNews = async () => {
            const response = await Axios.get('https://newsapi.org/v2/top-headlines?country=mx&apiKey=f944de61f06c4a9a878e0ec0c5030ce6&q=covid&pageSize=3')
            if ( response.status === 200 ) {
                const items = response.data.articles.map(item =>{
                    return {
                        fullTitle: item.title,
                        title: item.title.substring(0,50).trim() + '...',
                        date: new Date(item.publishedAt).toLocaleString(),
                        body: item.content,
                        img: item.urlToImage,
                        url: item.url
                    }
                })                
                setNews(items);
                setLoading(false);                
            }     
        }
        fetchNews();
    },[])
    const openUrl = (data) =>{        
        navigate('/news',{state:{data:data}})
    }
    return(
        <NewsHolder>
            <NewsTitle>Noticias GEB</NewsTitle>
            {loading ? <Loader type="Grid" height={100} width={100} /> :
                NEWS.map( ( item, index ) =>
                <NewsItem key={index + 'newsItem'} position={index%2 ? "" : "rigth" }>
                    <NewsImage url={item.img} />
                    <NewsSpace>&nbsp;</NewsSpace>
                    <NewsTextHolder>                                                
                            <NewsText>
                                {item.title}
                            </NewsText>
                        
                            <Image src={"/images/icons/ico_flecha.svg"} onClick={()=>openUrl(item)} />                        
                    </NewsTextHolder>
                </NewsItem> 
            )}        
            
        </NewsHolder>
    )
}

export default NewsSection