import { theme } from "../theme";

export const NAVIGATION_ITEMS = {
  rigthItems: [
      {
        route: "/main",
        text: "/ Inicio"
      },
      /*{
        route: "/aboutus",
        text: "/ Nosotros"
      },
      {
        route: "/news",
        text: "/ Noticias"
      },
      {
        route: "/join",
        text: "/ Únete al equipo"
      },*/
      {
        
        text: "Tu cuenta",
        button: true,
        backgroundColor: theme.colors.primary,        
      }
    ],
    logo: "/images/logos/logo_estrella_flat.svg",
    leftItems: [],
    navProps: {
      backgroundColor:"rgba(155,155,155,0.2)"
    }
  };
  