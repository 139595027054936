import styled from "styled-components";

export const InfoSectionContainer = styled.div`
    border-top: 4px solid ${ ({ theme }) => theme.colors.primary };
    border-bottom: 2px solid ${ ({ theme }) => theme.colors.primary };
    padding: 1.5rem 2rem;
    background-color: ${ ({ theme }) => theme.colors.ligthSecondary };
    display: flex;
    flex-direction: row;
    flex-wrap:wrap;
`;

export const InfoItem = styled.div`    
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    flex: 1;
`;

export const ImageInfo = styled.img`    
    width: 10rem;
    margin-bottom: 1rem;        
`;

export const InfoTitle = styled.p`
    font-size: 1.4rem;
    color: ${ ({ theme }) => theme.colors.primary };
    margin: 0;
    font-family: Eras Light;
`;

export const InfoNumber = styled.p`
    font-size: 2.8rem;
    color: #4f4f4f;
    font-family: Eras light;
    margin: 0;
    padding:0;
    font-style: italic;    
`;

export const InfoSubtitle = styled.p`
    font-size: 1.1rem;
    color: #4f4f4f;
    font-family: Eras Light;
    margin: 0;
    margin-top: -.8rem;
    padding:0;
    font-weight: lighter;
`;
