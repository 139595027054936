export const FOOTER_ITEMS=[
{
    title: "Ligas Rápidas",
    items: [{
        text:"Facturación.",
        route:""
    },{
        text:"Aviso de Privacidad.",
        route:""
    },{
        text:"Términos y condiciones.",
        route:""
    },{
        text:"Preguntas Frecuentes.",
        route:""
    },{
        text:"Políticas internas.",
        route:""
    },{
        text:"Nuestro Grupo.",
        route:""
    },{
        text:"Reglamentos.",
        route:""
    },{
        text:"Transporte.",
        route:""
    },{
        text:"Corporativo.",
        route:""
    },{
        text:"Paquetería.",
        route:""
    },{
        text:"Senderos.",
        route:""
    },{
        text:"Centrales de autobuses.",
        route:""
    },{
        text:"Jurídico.",
        route:""
    }]
},
{
    title: "Boletos de autobús a",
    items: [{
        text:"CDMX.",
        route:""
    },{
        text:"Guadalajara.",
        route:""
    },{
        text:"Monterrey.",
        route:""
    },{
        text:"Puerto Vallarta.",
        route:""
    },{
        text:"Querétaro.",
        route:""
    },{
        text:"Aguascalientes.",
        route:""
    },{
        text:"Acapulco.",
        route:""
    },{
        text:"Tijuana.",
        route:""
    },{
        text:"Puebla.",
        route:""
    },{
        text:"Puebla.",
        route:""
    },{
        text:"Pachuca.",
        route:""
    },{
        text:"Cd. Victoria.",
        route:""
    },{
        text:"Cd. Juárez.",
        route:""
    },{
        text:"Chihuahua.",
        route:""
    }]
}]