import React from 'react'
import {
    FooterHolder, 
    FooterSection, 
    FooterSectionTitle,
    FooterSectionText,
    FooterSectionItems,
    SectionHolder
} from './style'
import { FOOTER_ITEMS } from '../../constants/footer'

const Footer = () => {
    return(
        <SectionHolder>
            <FooterHolder>
                { FOOTER_ITEMS.map( (item,index) =>
                    <FooterSection key={index}>
                        <FooterSectionTitle>{item.title}</FooterSectionTitle>
                        <FooterSectionItems>
                            {item.items.map((i,x)=><FooterSectionText key={x}>{i.text}</FooterSectionText>)}
                        </FooterSectionItems>
                    </FooterSection>
                ) }     
                
            </FooterHolder>
            <FooterSectionItems>
                <img alt="logo" src={"/images/icons/logo_estrella_footer.svg"} style={{height: "3rem", padding: "1rem 0", flex: 1}} />
                <FooterSectionText centerText lineHeight={"3rem"} flex={5}>
                    Created by <b>Estrella Blanca Digital.</b> All rights reserved 2020.
                </FooterSectionText>
                <img alt="logo" src={"/images/icons/ico_facebook.svg"} style={{height: "1rem", right:"0", padding: "1rem"}} />
                <img alt="logo" src={"/images/icons/ico_instagram.svg"} style={{width: "1rem", height: "1rem", right:"0", padding: "1rem"}} />
                <img alt="logo" src={"/images/icons/ico_twitter.svg"} style={{width: "1rem", height: "1rem", right:"0", padding: "1rem"}} />
                <img alt="logo" src={"/images/icons/ico_youtube.svg"} style={{width: "1rem", height: "1rem", right:"0", padding: "1rem"}} />
            </FooterSectionItems>
        </SectionHolder>
    )
}

export default Footer;