import styled from "styled-components";
import { breakpoints } from "../../breakpoints";

export const SectionHolder = styled.div`
    padding: 1.5rem;
    background-color: ${ ({ theme }) => theme.colors.strongSecondary };
    color: ${ ({ theme }) => theme.colors.white };    
`;

export const FooterHolder = styled.div`    
    display: flex;    
    flex-wrap: wrap;
    padding: 1rem;
    @media (min-width: 0px) {
        flex-direction: column;
    }    
    @media ${ breakpoints.tablet} {
        flex-direction: row;
    }    
`;

export const FooterSection = styled.div`
    padding: 1.5rem;
    flex: 1;    
    height: 15rem;
`

export const FooterSectionTitle = styled.p`
    width: 100%;
    border-bottom: 1px solid #717273;    
    font-weight: bolder;
    padding-bottom: 0.5rem;
`;

export const FooterSectionText = styled.p`    
    color: #717273;    
    font-size: .8rem;    
    margin: 0.3rem 0.5rem;
    width: 33.333%;
    text-align: ${ ({ centerText }) => centerText ? "center" : ""};
    vertical-align: ${ ({ centerText }) => centerText ? "center" : ""};
    line-height: ${ ({ lineHeight }) => lineHeight ? lineHeight : ""};
    flex: ${ ({ flex }) => flex ? flex : ""};
`;

export const FooterSectionItems = styled.div`    
    display: flex;
    flex-direction: row;    
    flex-wrap: wrap;
`;
