import React from 'react'
import { 
    InfoSectionContainer,
} from './style'
import { BUSSINESS } from '../../constants/sections'
import InfoItem from './InfoItem';


const InfoSection = props => {    
    

    
    return(       
        <InfoSectionContainer>
            { BUSSINESS.items.map( (item, key) =>
                <InfoItem {...item} key={key} />
            )}                                                  
        </InfoSectionContainer>    
    )
}

export default InfoSection