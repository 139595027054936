export const BUSSINESS = {
    title: "",
    items:[{
        image: "/images/icons/corporativo.svg",
        title: "Corporativo",
        key: 1,
        number: "2000",
        subtitle: "empleados"
    },{
        image: "/images/icons/transporte.svg",
        title: "Transporte",
        key: 2,
        number: "6000",
        subtitle: "empleados"
    },{
        image: "/images/icons/paqueteria.svg",
        title: "Paqueteria",
        key: 3,
        number: "1000",
        subtitle: "empleados"
    },{
        image: "/images/icons/senderos.svg",
        title: "Senderos",
        key: 4,
        number: "500",
        subtitle: "empleados"
    },{
        image: "/images/icons/centrales.svg",
        title: "Centrales",
        key: 5,
        number: "1500",
        subtitle: "empleados"
    }]
}