import React from 'react'
import { 
    ContactHolder,
    ContactTitle,
    ContactBody,    
} from './style'
import { Input, Row, Button, TextArea } from '../../components'


const ContactSection = props => {
    return(        
        <ContactHolder>
            <ContactTitle>Escribenos</ContactTitle>
            <ContactBody>
                Comentanos que es lo que podriamos hacer para mejorar tu experiencia con nosotros, tu opinion es muy importante para nuestro grupo                
            </ContactBody>
            <Row padding={"0 1.5rem"}>
                <Input  placeholder="Correo" />
                <Input  placeholder="Asunto" />
            </Row>
            <Row padding={"1rem 1.5rem"}>
                <TextArea />
            </Row>
            <Button text = {"Enviar"} />            
        </ContactHolder>
    )
}

export default ContactSection